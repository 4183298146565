"use client";

import { Collapse } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { twMerge } from "tailwind-merge";

interface TextProps {
    title?: string;
    paragraphs?: string[];
    textColor?: string;
    titleSize?: string;
    textSize?: string;
    subTitleSize?: string;
    titlePlacement?: string;
    paddings?: string;
    hasText?: boolean;
    hasSubTitle?: boolean;
    subTitle?: string;
    hasPretitle?: boolean;
    pretitle?: string;
    leading?: string;
    pretitleSize?: string;
    leadingText?: string;
    pretitleStyle?: string;
    hasReadMore?: boolean;
    paragraphSlice?: number;
}

export default function Text({
    title = "So läuft es ab",
    paragraphs = [
        "Den Ablauf der persönlichen Sitzung vor Ort in der Praxis oder in Form der Telefonberatung gestaltest du mit. Wenn du zum Beispiel Fragen hast, die du gerne mit mir besprechen möchtest, kannst du diese zu Beginn mit mir anschauen. Falls keine Fragen bestehen, frage ich in der Regel nach, ob ich das Energiefeld betrachten und die aktuelle Situation erläutern soll. ",
        " In einem zweiten Teil kann bei einer Sitzung vor Ort die Energiefeld-Instandsetzung stattfinden, falls das gewünscht und notwendig ist. Es kann aber auch sein, dass die gesamte Gesprächszeit genutzt wird, um die aktuelle Situationen zu besprechen. Das ist ganz individuell und wird vor Ort miteinander angeschaut. Zusätzlich kommt es auch darauf an, ob das Einzelgespräch eine halbe oder eine ganze Stunde dauert.",
    ],
    titleSize = "text-xl3 lg:text-6xl",
    textSize = "lg:text-xl1 text-lg",
    subTitleSize = "lg:text-xl3 text-xl",
    paddings = "px-3 lg:px-74 py-25 w-full md:px-24",
    hasText = true,
    textColor = "text-black",
    hasSubTitle = false,
    subTitle = "Auch wenn du weit weg bist",
    titlePlacement = "gap-6",
    hasPretitle = false,
    pretitle = "",
    leading = "leading-14",
    pretitleSize = " text-[28px]",
    leadingText = "leading-8.5 md:leading-7",
    pretitleStyle = "font-normal",
    hasReadMore = false,
    paragraphSlice = 2,
}: TextProps) {
    const [opened, { toggle }] = useDisclosure(false);
    return (
        <div
            className={twMerge(
                "bg-red-400 flex-col justify-start inline-flex",
                paddings,
                textColor,
                titlePlacement,
            )}
        >
            {hasPretitle && (
                <div
                    className={twMerge(
                        "lg:text-xl3 font-['Avenir Next Condensed']",
                        textColor,
                        pretitleSize,
                        pretitleStyle,
                    )}
                >
                    {pretitle}
                </div>
            )}

            <div className={twMerge("self-stretch py-2 font-bold", titleSize, leading)}>
                {title}
            </div>
            {hasSubTitle && (
                <div
                    className={twMerge(
                        "font-bold  leading-7 lg:leading-10",
                        subTitleSize,
                        textColor,
                    )}
                >
                    {subTitle}
                </div>
            )}

            {hasText && (
                <div
                    className={twMerge(
                        "self-stretch font-normal ",
                        textSize,
                        leadingText,
                    )}
                >
                    <div className="lg:hidden block space-y-3">
                        {hasReadMore ? (
                            <>
                                {paragraphs.length > 1 && (
                                    <>
                                        {paragraphs
                                            .slice(0, paragraphSlice)
                                            .map((paragraph) => (
                                                <div key={paragraph}>
                                                    {paragraph}
                                                    <br />
                                                </div>
                                            ))}

                                        {!opened && (
                                            <button
                                                onClick={toggle}
                                                className="text-black text-base font-bold  underline leading-normal"
                                                type="button"
                                            >
                                                Mehr lesen
                                            </button>
                                        )}
                                        {paragraphs
                                            .slice(paragraphSlice)
                                            .map((paragraph) => (
                                                <Collapse in={opened} key={paragraph}>
                                                    <div>
                                                        {paragraph}
                                                        <br />
                                                    </div>
                                                </Collapse>
                                            ))}
                                        {opened && (
                                            <button
                                                onClick={toggle}
                                                className="text-black text-base font-bold  underline leading-normal"
                                                type="button"
                                            >
                                                Weniger lesen
                                            </button>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <div className="space-y-3">
                                {paragraphs.map((paragraph) => (
                                    <div key={paragraph}>
                                        {paragraph}
                                        <br />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className="lg:block hidden space-y-3">
                        {paragraphs.map((paragraph) => (
                            <div key={paragraph}>
                                {paragraph}
                                <br />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}
