"use client";
import { toggleCaption } from "@/utils";
import dynamic from "next/dynamic";
import videojs from "video.js";
import type Component from "video.js/dist/types/component";
// import VideoJS from "./Player";

const VideoJS = dynamic(() => import("./Player"), { ssr: false });

interface PlayerInPageProps {
    videoId: string;
    thumbnail?: string;
    darkMode?: boolean;
}

export default function PlayerInPage({
    videoId,
    thumbnail,
    darkMode = false,
}: PlayerInPageProps) {
    const BASE_URL =
        process.env.CLOUDFLARE_STREAM_URL ||
        "https://customer-n0gr865zkpr6qbv9.cloudflarestream.com";
    // const dashUrl = `${BASE_URL}/${videoId}/manifest/video.mpd`;
    const hlsUrl = `${BASE_URL}/${videoId}/manifest/video.m3u8`;

    const videoJsOptions = () => {
        return {
            autoplay: false,
            controls: true,
            sources: [
                // {
                //     src: dashUrl,
                //     type: "application/dash+xml",
                // },
                {
                    src: hlsUrl,
                    type: "application/x-mpegURL",
                },
            ],
            aspectRatio: "16:9",
            preload: "auto",
            navigationUI: "hide",
            poster: thumbnail,
            playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
            controlBar: {
                skipButtons: {
                    backward: 10,
                    forward: 10,
                },
            },

            sm: {
                fullscreen: true,
                aspectRatio: "16:9",
                fill: true,
            },
        };
    };

    return (
        <>
            <VideoJS
                options={videoJsOptions()}
                onReady={(player) => {
                    const controlBar = player.getChild("controlBar");
                    player.on("play", () => {
                        darkMode && controlBar?.addClass("dark");
                    });
                    darkMode &&
                        controlBar?.getChild("CurrentTimeDisplay")?.addClass("dark");
                    darkMode &&
                        controlBar?.getChild("DurationDisplay")?.addClass("dark");

                    darkMode && controlBar?.getChild("TimeDivider")?.addClass("dark");
                    darkMode &&
                        controlBar?.getChild("ProgressControl")?.addClass("dark");
                }}
                onLoadedData={(player) => {
                    //@ts-ignore
                    const CapButton: Component = videojs.getComponent("Button");
                    //@ts-ignore
                    const tracks = player.textTracks();
                    //@ts-ignore
                    const capButton = new CapButton(player, {
                        clickHandler: () => {
                            toggleCaption(tracks[1].mode, tracks, capButton);
                        },

                        className: "sub-button sub-button-off",
                    });
                }}
            />
        </>
    );
}
