"use client";

import type { Testimonial, Video, Videos, VideosList } from "./types";

export const bigCardContent = [
    {
        title: "Stabilität macht glücklich",
        content:
            "Ein glücklicher Mensch ist für mich jemand, der in seinem Leben steht, an dem Punkt, wo er stehen will. Oder mit dem Punkt, wo er steht, umgehen kann und für sich zufrieden ist und eigentlich auch mit dem zufrieden ist, was er hat.",
        img: "/images/img1.png",
        url: "/blog-post",
    },
    {
        title: "Hochsensibilität: Tipps und Umgang",
        content:
            "Bei Hochsensibilität gibt es verschiedene Tipps, die dir zeigen sollen, wie du dich abgrenzen kannst. Viele sehen sie als eine Gabe an. Es steckt jedoch etwas anderes dahinter. Hochsensible Menschen reagieren auf Reize, die ihr System nur schwer verarbeiten kann.",
        img: "/images/img2.png",
        url: "/blog-post",
    },
    {
        title: "Ängste fordern neue Perspektive",
        content:
            "Ängste lähmen, doch sie zeigen auch auf, wo etwas nicht stimmt. Mit der Stärkung von Energiefeldern ermöglicht Rico Brunner Menschen aus eigener Kraft heraus, ihre Haltung zur Angst zu verändern und Schritt für Schritt eine neue Perspektive zu entwickeln.",
        img: "/images/img3.png",
        url: "/blog-post",
    },
    {
        title: "Entspannt mit Rückschlägen umgehen",
        content:
            "Ein Mensch, der vertrauensvoll durch das Leben geht, der geht mit Rückschlägen entspannter um. Der geht mit Niederlagen entspannter um und weiss, dass nach jeder Niederlage eine Gelegenheit kommt, wieder weiter zu kommen und auch zu gewinnen», sagt Energiefeldmechaniker Rico Brunner",
        img: "/images/img4.png",
    },
];

// biome-ignore lint/suspicious/noExplicitAny: we dont know the type of tracks and button
export const toggleCaption = (mode: string, tracks: any, button: any) => {
    if (mode === "showing") {
        tracks[1].mode = "hidden";
        button.removeClass("sub-button-on");
        button.addClass("sub-button-off");
    } else {
        tracks[1].mode = "showing";
        button.removeClass("sub-button-off");
        button.addClass("sub-button-on");
    }
};

export const videoJsOptions = (dashUrl: string, hlsUrl: string) => {
    return {
        autoplay: true,
        controls: true,
        sources: [
            {
                src: hlsUrl,
                type: "application/x-mpegURL",
            },
        ],
        preload: "auto",
        navigationUI: "hide",
        playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
        controlBar: {
            skipButtons: {
                backward: 10,
                forward: 10,
            },
        },
        sm: {
            fullscreen: true,
            aspectRatio: "16:9",
            fill: true,
        },
    };
};

export const setIcon = (active: string | undefined) => {
    switch (active) {
        case "Kostenlos": {
            return "/Icons/TabIcons/likeCyan.svg";
        }
        case "Neu": {
            return "/Icons/TabIcons/plusCyan.svg";
        }
        case "Coming-soon": {
            return "/Icons/TabIcons/clockCyan.svg";
        }
        case "Alle": {
            return "/Icons/TabIcons/tvCyan.svg";
        }
        case "Kostenpflichtig": {
            return "/Icons/TabIcons/paidCyan.svg";
        }
        case "Meine-Masterclass": {
            return "/Icons/TabIcons/userCyan.svg";
        }
        case "Favoriten": {
            return "/Icons/TabIcons/starCyan.svg";
        }
    }
};

export const shuffle = (array: Testimonial[]) => {
    return array.sort(() => Math.random() - 0.5);
};

export const formatDuration = (duration: string | number | undefined) => {
    if (typeof duration === "string") {
        const minutes = `${Math.floor(Number.parseInt(duration) / 60)}:${
            Number.parseInt(duration) % 60 < 10 ? "0" : ""
        }${Number.parseInt(duration) % 60}`;
        return minutes;
    }
    if (typeof duration === "number") {
        const minutes = `${Math.floor(duration / 60)}:${duration % 60 < 10 ? "0" : ""}${
            duration % 60
        }`;
        return minutes;
    }
};
export const formatDurationToMinutes = (duration: string) => {
    const minutes = Math.ceil(Number.parseInt(duration) / 60);
    return minutes;
};
export const formatDurationToHoursAndMinutes = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours !== 0 ? `${hours} hrs` : ""}   ${
        remainingMinutes !== 0 ? `${remainingMinutes} min ` : ""
    } `;
};
export const getModuleVideosWithSub = (videos: VideosList, moduleName: string) => {
    const moduleVideos = videos.videos.find(
        (module: Videos) => module.name === moduleName,
    );
    return moduleVideos?.videos.flatMap((video: Video) => {
        const subVideos = video.sub_videos
            ? video.sub_videos.map((subVideo: Video) => subVideo)
            : [];
        return [video, ...subVideos];
    });
};

export const testimonialImg = [
    {
        url: "/images/testimonials/videoTestemonial/Ausgeglichener_Thumbnail_White.jpg",
        name: "Ausgeglichener_Thumbnail_White",
    },
    {
        url: "/images/testimonials/videoTestemonial/Druckumgehen_Thumbnail_White.jpg",
        name: "Druckumgehen_Thumbnail_White",
    },
    {
        url: "/images/testimonials/videoTestemonial/Stabiler_Thumbnail_White.jpg",
        name: "Stabiler_Thumbnail_White",
    },
    {
        url:"/images/testimonials/videoTestemonial/Hamsterrad_Thumbnail_White.png",
        name:"Hamsterrad_Thumbnail_White"
    }
];

export const country_list = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antigua &amp; Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia &amp; Herzegovina",
    "Botswana",
    "Brazil",
    "British Virgin Islands",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Cape Verde",
    "Cayman Islands",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Cote D Ivoire",
    "Croatia",
    "Cruise Ship",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Polynesia",
    "French West Indies",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kuwait",
    "Kyrgyz Republic",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Namibia",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Norway",
    "Oman",
    "Pakistan",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Pierre &amp; Miquelon",
    "Samoa",
    "San Marino",
    "Satellite",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "South Africa",
    "South Korea",
    "Spain",
    "Sri Lanka",
    "St Kitts &amp; Nevis",
    "St Lucia",
    "St Vincent",
    "St. Lucia",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor L'Este",
    "Togo",
    "Tonga",
    "Trinidad &amp; Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks &amp; Caicos",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "Uruguay",
    "Uzbekistan",
    "Venezuela",
    "Vietnam",
    "Virgin Islands (US)",
    "Yemen",
    "Zambia",
    "Zimbabwe",
];
